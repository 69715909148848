// 
// countdown.js
// 

const countDownDate = new Date("Dec 4, 2021 10:00:00").getTime();
let x = setInterval(function() {

    let now = new Date().getTime();
    let distance = countDownDate - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.querySelector('#countdown-days').innerHTML = days;
    document.querySelector('#countdown-hours').innerHTML = hours;
    document.querySelector('#countdown-minutes').innerHTML = minutes;
    document.querySelector('#countdown-seconds').innerHTML = seconds;
    
    if (distance < 0) {
      clearInterval(x);
      document.querySelector('.countdown').style.display = 'none';
    }
}, 1000);